import React, { useState } from 'react'
import styles from './ParticipantList.module.css'

export default function ParticipantList({ participantsParam, onParticipantChange }) {
    const [participants, setParticipants] = useState(participantsParam || []);
    const [newParticipantName, setNewParticipantName] = useState("");

    const handleAddParticipantButtonClick = () => {
        const newParticipantList = participants.concat([newParticipantName])
        console.log(newParticipantList)
        setParticipants(newParticipantList)
        onParticipantChange(newParticipantList)
        setNewParticipantName("")
    }

    const handleRemoveParticipantButtonClick = (index) => {
        var newParticipants = participants.filter((_, i) => i !== index)
        setParticipants(newParticipants)
        onParticipantChange(newParticipants)
    }


    return (
        <div className={styles.listWrapper}>
            <h2>Participants</h2>
            <div className={styles.control}>
                <input placeholder="Name" type="text" onKeyDown={(e) => e.key === "Enter" ? handleAddParticipantButtonClick() : null} onChange={(e) => setNewParticipantName(e.target.value)} value={newParticipantName}></input>
                <span className="button secondary" onClick={() => { handleAddParticipantButtonClick() }}>Add</span>
            </div>
            <ul className={styles.list}>
                {participants.map((participant, index) => (
                    <li key={index} className={styles.listItem}>
                        <span className={styles.participantTitle}>{participant}</span>
                        <span className={`${styles.participantRemoveButton} ${styles.button}`} onClick={() => handleRemoveParticipantButtonClick(index)}>x</span>
                    </li>
                ))}
            </ul>
        </div >
    )
}
