import React from 'react';
import BackgroundImage from '../components/BackgroundImage';
import styles from './LandingPage.module.css';

export default function LandingPage() {
  return (
    <div>
      <BackgroundImage />

      <div className={styles.landingPageContainer}>
        <div className={styles.title}>
          <h1>
            Eric
            <br /> Lantz
          </h1>
        </div>

        <div className={styles.description}>
          <h3>Developer</h3>
          <p>Based In Stockholm. Sweden.</p>
        </div>

        <div className={styles.rightNow}>
          <h2>Right Now</h2>
          <p>
            Working at{' '}
            <a target="_blank_" href="https://ving.se">
              Ving AB
            </a>{' '}
            In Stockholm, Sweden
          </p>
        </div>

        <div className={styles.getInTouch}>
          <h2>Get in touch</h2>
          <ul>
            <li>
              <a target="_blank_" href="https://github.com/ericlantz">
                Github
              </a>
            </li>
            <li>|</li>
            <li>
              <a
                target="_blank_"
                href="https://linkedin.com/in/eric-lantz-116497105"
              >
                LinkedIn
              </a>
            </li>
            <li>|</li>
            <li>
              <a target="_blank_" href="mailto:elantz12@gmail.com">
                Email
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
