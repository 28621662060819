import React, { useState } from 'react'
import styles from './GiftPricePicker.module.css'

export default function GiftPricePicker({ priceParam, currencyParam, onPriceChange, onCurrencyChange }) {
    const [price, setPrice] = useState(priceParam)
    const [currency, setCurrency] = useState(currencyParam)

    const handlePriceChange = (newPrice) => {
        setPrice(newPrice);
        onPriceChange(newPrice)
    }

    const handleCurrencyChange = (newCurrency) => {
        setCurrency(newCurrency);
        onCurrencyChange(newCurrency)
    }

    return (
        <div className={styles.wrapper}>
            <h2>Gift Price</h2>
            <div className={styles.control}>
                <input placeholder="Price" value={price || ""} type="number" onChange={(e) => handlePriceChange(e.target.value)}></input>
                <input placeholder='Currency' value={currency || ""} type="text" onChange={(e) => handleCurrencyChange(e.target.value)}></input>
            </div>
        </div>
    )
}
