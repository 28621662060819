import React from "react";
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import LandingPage from './pages/LandingPage';
import SecretSanta from "./pages/SecretSanta";

// const routing = (
//   <Router>
//     <Switch>
//       <Route exact path="/">
//         <LandingPage />
//       </Route>
//     </Switch>
//   </Router>
// );

// ReactDOM.render(routing, document.getElementById('root'));

const routing = (  
    <Router>              
      <div>
        <Routes>          
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/secretsanta" element={<SecretSanta />} />
        </Routes>
      </div>
    </Router>
  );

ReactDOM.render(
  routing,
  document.getElementById('root')
);
