import React, { useEffect, useState } from 'react'
import { Buffer } from "buffer"
import { shuffle } from '../../helpers/shuffle';
import styles from './InvitePage.module.css'


export default function InvitePage({ participantsParam, giftAmountParam, currencyParam }) {
    const [participants, setParticipants] = useState(participantsParam)
    const [giftAmount, setGiftAmount] = useState(giftAmountParam)
    const [currency, setCurrency] = useState(currencyParam)
    const [shuffledReceivers, setShuffledReceivers] = useState([])

    useEffect(() => {
        setParticipants(participants)
        setGiftAmount(giftAmount)
        setCurrency(currency)
        setShuffledReceivers(shuffle(participants))
    }, [participants, giftAmount, currency])

    const generateBase64 = (indexParam) => {
        console.log("Generating", participants, shuffledReceivers)
        const data = {
            receiver: shuffledReceivers[indexParam],
            giftAmount: giftAmount,
            currency: currency
        }
        const buffer = Buffer.from(JSON.stringify(data))
        return buffer.toString("base64")
    }

    const generateInviteLink = (indexParam) => {
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}?data=${generateBase64(indexParam)}`
    }

    return (
        <div className={styles.listWrapper}>
            <h2>Invite links for:</h2>
            <ul className={styles.list}>
                {participants.map((p, i) => (
                    <li className={styles.listItem} key={i}>
                        <span>{p}</span>
                        <span className="button secondary" onClick={() => { navigator.clipboard.writeText(generateInviteLink(i)) }}>Copy</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}
