import React from "react";
import styles from "./ChristmasBackgroundImage.module.css";

function ChristmasBackgroundImage() {
  return (
    <div className={styles.bg}>
      <div className={styles.bgImage} />
    </div>
  );
}

export default ChristmasBackgroundImage;
