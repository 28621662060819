export function shuffle(originalArray) {
    let tempArray = [...originalArray]
    tempArray = tempArray.sort((a, b) => 0.5 - Math.random());
    console.log("TempArray", tempArray)

    let tempArrayReceivers = [...tempArray]
    const firstElement = tempArrayReceivers.shift()
    tempArrayReceivers.push(firstElement);

    console.log("TempArrayReceivers", tempArrayReceivers)

    let receivers = [];
    for (var i = 0; i < originalArray.length; i++) {
        const index = tempArray.indexOf(originalArray[i]);
        receivers.push(tempArrayReceivers[index])
    }

    console.log("Receivers", receivers)

    return receivers;
}