import React from 'react'
import styles from './AssignedReceiver.module.css'

export default function AssignedReceiver({ receiver, giftAmount, currency }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.introTitle}>Your assigned recipent is</div>
            <h2 className={styles.receiver}>{receiver}</h2>
            <div className={styles.outroTitle}>The gift should be purchased for around {giftAmount} {currency}</div>
        </div>
    )
}
