import React from 'react'

export default function JustifyTitle({ title, subtitle }) {
    return (
        <div style={{ maxWidth: "20em" }}>
            <h1 style={{ textJustify: "inter-character", textAlign: "justify", textAlignLast: "justify" }}>{title}</h1>
            <h2 style={{ color: "darkred", textJustify: "inter-character", textAlign: "justify", textAlignLast: "justify" }}>{subtitle}</h2>
        </div >
    )
}
