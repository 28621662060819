import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Buffer } from "buffer"
import styles from './SecretSanta.module.css';
import AssignedReceiver from '../components/secretsanta/AssignedReceiver';
import ParticipantList from '../components/secretsanta/ParticipantList';
import JustifyTitle from '../components/secretsanta/Title';
import ChristmasBackgroundImage from '../components/secretsanta/ChristmasBackgroundImage';
import GiftPricePicker from '../components/secretsanta/GiftPricePicker';
import InvitePage from '../components/secretsanta/InvitePage';

export default function SecretSanta() {
    const [searchParams] = useSearchParams();
    const [participants, setParticipants] = useState([]);
    const [viewCurrentUsersAssignedReceiver, setViewCurrentUsersAssignedReceiver] = useState(false)
    const [viewUserBasedUrls, setViewUserBasedUrls] = useState(false);
    const [userReceiver, setUserReceiver] = useState("")
    const [generateButtonIsEnabled, setGenerateButtonIsEnabled] = useState(false)

    const [giftAmount, setGiftAmount] = useState(700)
    const [currency, setCurrency] = useState("SEK")

    useEffect(() => {
        var data = searchParams.get("data")
        if (data) {
            var json = Buffer.from(data, "base64")

            var parsed = JSON.parse(json);
            if (parsed && parsed.receiver) {
                setViewCurrentUsersAssignedReceiver(true)
                setGiftAmount(parsed.giftAmount)
                setCurrency(parsed.currency)
                setUserReceiver(parsed.receiver)
            } else {
                alert("Data is broken")
                console.error("Parse error", parsed, parsed.participants, parsed.currentUser)

            }
            console.log(parsed)
        }
    }, [searchParams])

    useEffect(() => {
        var uniqueParticipants = [...new Set(participants)];
        var participantsAreValid = uniqueParticipants.length >= 2;
        setGenerateButtonIsEnabled(participantsAreValid);
    }, [participants])

    const handleGenerateButtonClick = () => {
        if (generateButtonIsEnabled) {
            setViewUserBasedUrls(true)
        }
    }

    const renderDecider = () => {
        if (viewCurrentUsersAssignedReceiver) {
            return (
                <div className={styles.wrapper}>
                    <AssignedReceiver receiver={userReceiver} giftAmount={giftAmount} currency={currency} />
                </div>
            )
        } else if (viewUserBasedUrls) {
            return (
                <div className={styles.wrapper}>
                    <InvitePage participantsParam={participants} currencyParam={currency} giftAmountParam={giftAmount} />
                </div>
            )
        } else {
            return (
                <div className={styles.wrapper}>
                    <GiftPricePicker priceParam={giftAmount} currencyParam={currency} onPriceChange={(p) => setGiftAmount(p)} onCurrencyChange={(c) => setCurrency(c)}></GiftPricePicker>
                    <ParticipantList className={styles.participantList} participantsParam={participants} onParticipantChange={(p) => setParticipants(p)} />
                    <div className={styles.control}>
                        <div className={`button primary ${generateButtonIsEnabled ? "" : "disabled"} `} type="button" onClick={() => handleGenerateButtonClick()}>Generate</div>
                    </div>
                </div >
            )
        }
    }

    return (
        <div style={{ height: "100vh" }} >
            <ChristmasBackgroundImage />
            <div className={styles.titleWrapper}>
                <JustifyTitle title="Secret Santa" subtitle="Generator" />
            </div>
            {renderDecider()}
        </div >
    )
}
