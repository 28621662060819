import React from "react";
import styles from "./BackgroundImage.module.css";

function BackgroundImage() {
  return (
    <div className={styles.bg}>
      <div className={styles.bgImage} />
      <div className={styles.overlay1} />
      <div className={styles.overlay2} />
    </div>
  );
}

export default BackgroundImage;
